/* GLOBAL */
.page {
  padding-top: 5px;
}

.page {
  background-color: @color-white;
  border-radius: 5pt;
  border: 1px solid darken(@color-backdrop, 2%);
  // color: #5e5e61;
  &.padded {
    padding: 20pt;
  }
}

.warning_download_excel {
  color: @color-primary;
  text-align: center;
}

/* END GLOBAL */

.grid_list_contracts {
  padding: 15px;
  width: 90%;
  margin: 0 auto;
  /* &.admin_view {
    margin: 0;
  } */
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  grid-template-areas:
    'title_page_contracts'
    'filter_contracts'
    'action_contracts'
    'list_contracts';
}

.title_page_contracts {
  grid-area: title_page_contracts;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.filter_contracts {
  grid-area: filter_contracts;
  display: grid;

  .search_contracts {
    grid-area: search_contract;
  }

  .filter_label {
    grid-area: filter_label;
  }

  .filter_status {
    grid-area: filter_status;
  }

  .reset_contracts {
    grid-area: reset;
  }

  .filter {
    position: relative;
    text-align: center;

    &:hover ul {
      display: block;
    }

    .search {
      background-color: transparent;
      padding: 10px 5px;
      border: none;
      outline: none;
      font-size: 16px;
      border-bottom: 2px solid lighten(@color-gray, 45%);
      border-radius: 4px;
      //   width: 100%;
    }

    .label-title {
      font-size: 13px;
      color: @color-gray;

      i {
        padding-right: 10px;
      }
    }

    ul {
      position: absolute;
      background: @color-white;
      list-style: none;
      min-width: 100%;
      text-align: left;
      margin: 0;
      padding: 0;
      left: 0;
      display: none;
      z-index: 1;
      border: 1px solid darken(@color-backdrop, 2%);
      max-height: 380px;
      overflow: auto;

      li {
        color: @color-gray;
        padding: 10px;
        border-bottom: 1px solid darken(@color-backdrop, 2%);
        margin: 0;
        font-size: 13px;
        font-weight: 300;

        .box {
          background: @color-gray;
        }

        .box:checked {
          background: @color-warning;
        }

        input {
          margin-right: 10px;
        }

        label {
          padding-left: 10px;
        }
      }
    }
  }
}

.action_contracts {
  grid-area: action_contracts;
  display: grid;

  .loader_download_contracts {
    display: flex;
    align-items: center;
    p {
      padding-left: 20px;
    }
  }

  .pagination {
    grid-area: pagination;
    list-style: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    overflow: hidden;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);

    .page-item {
      float: left;
      &.active {
        .page-link {
          color: @color-primary !important;
          background-color: darken(@color-backdrop, 5%) !important;
          border-color: @color-primary !important;
        }
      }
    }

    .page-link {
      padding: 0.5rem 1rem;
      text-align: center;
      box-shadow: none !important;
      border-color: @color-primary !important;
      color: @color-gray;

      &:hover {
        background-color: @color-backdrop;
      }
    }
  }

  .button_actions {
    grid-area: signature_actions;
    .download_files_contracts {
      display: flex;
      align-items: center;
      padding-right: 20px;
      color: @color-primary;
      i {
        font-size: 25px;
        cursor: pointer;
      }
    }

    i {
      padding-right: 15px;
      font-size: 25px;
      cursor: pointer;
    }
  }
}

.list_contracts {
  grid-area: list_contracts;
  overflow-x: auto;

  .table_list_contracts {
    flex: 1;
    display: grid;
    border-collapse: collapse;
    grid-template-columns:
      minmax(50px, auto)
      minmax(200px, auto)
      minmax(130px, auto)
      minmax(200px, auto)
      minmax(120px, auto)
      minmax(135px, auto)
      minmax(120px, auto);

    &.isAdmin {
      grid-template-columns:
        minmax(50px, auto)
        minmax(200px, auto)
        minmax(130px, auto)
        minmax(200px, auto)
        minmax(120px, auto)
        minmax(120px, auto);
    }

    .center_table_th {
      text-align: center;
    }

    .center_table_data {
      text-align: center;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .title_page_contracts {
    h1 {
      font-size: 16px;
    }
  }
  .filter_contracts {
    grid-area: filter_contracts;
    grid-template-areas:
      'search_contract'
      'filter_label'
      'filter_status'
      'reset';
    grid-template-columns: 1fr;
    gap: 20px 0;

    .filter {
      text-align: left;

      .search {
        width: 100%;
      }
    }
  }

  .action_contracts {
    margin-top: 16px;
    grid-area: action_contracts;
    grid-template-areas:
      'pagination'
      'signature_actions';
    grid-template-columns: 1fr;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
  .filter_contracts {
    grid-area: filter_contracts;
    grid-template-areas:
      'filter_label filter_status'
      'search_contract reset';
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;

    .filter {
      text-align: left;
    }
  }

  .action_contracts {
    margin-top: 50px;
    grid-area: action_contracts;
    grid-template-areas:
      'pagination'
      'signature_actions';
    grid-template-columns: 1fr;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  .filter_contracts {
    grid-area: filter_contracts;
    grid-template-areas:
      'search_contract search_contract search_contract'
      'filter_label filter_status reset';
    gap: 20px 0;
    .search_contract {
      grid-column: 1 / 4;
      grid-row: 1;
    }
    .filter {
      text-align: left;
      .search {
        width: 100%;
      }
    }
  }

  .action_contracts {
    margin-top: 10px;
    grid-area: action_contracts;
    grid-template-columns: 1fr 1fr;
    gap: 0 30px;
    grid-template-areas: 'pagination signature_actions';

    .button_actions {
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .filter_contracts {
    grid-area: filter_contracts;
    grid-template-areas:
      'search_contract search_contract search_contract'
      'filter_label filter_status reset';
    gap: 20px 0;
    .search_contract {
      grid-column: 1 / 4;
      grid-row: 1;
    }
    .filter {
      text-align: left;
      .search {
        width: 100%;
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .filter_contracts {
    grid-area: filter_contracts;
    grid-template-areas: 'search_contract filter_label filter_status reset';
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    .search_contract {
      grid-column: 1;
    }
    .filter {
      text-align: center;
      .search {
        width: 80%;
      }
    }
    align-items: center;
  }
}
