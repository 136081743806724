.new_contract_massive_view {
  width: 85%;
  margin: 0 auto;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  gap: 10px 0;
  grid-template-areas:
    'title_massive'
    'contract_info_massive'
    'signers_massive'
    'errors_massive'
    'info_to_upload_massive';

  .title_massive {
    grid-area: title_massive;
  }

  .contract_info_massive {
    grid-area: contract_info_massive;
    display: grid;
    width: 100%;

    &.padded {
      padding: 20px;
    }

    .dropzone_massive {
      grid-area: dropzone_massive;
    }

    .info_files {
      grid-area: info_files;
      border: 1px solid #f8c952;
      border-radius: 4px;
      color: #ffb432;
      font-size: 14px;
      min-width: 100px;
      word-wrap: break-word;

      i {
        padding-left: 18px;
        padding-top: 10px;
      }

      ul li {
        padding: 5px;
      }
    }

    .title_contract_massive {
      grid-area: title_contract_massive;
    }

    .labels_massive {
      grid-area: labels_massive;
      .labels_flex_massive {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        div {
          width: 100%;
        }
      }
    }

    .list_labels_massive {
      grid-area: list_labels_massive;
      overflow-x: auto;
      white-space: nowrap;
    }

    .expires_massive {
      grid-area: expires_massive;
      .inputs_date_massive {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;
      }
      .inputs_date_massive_checkbox {
        display: flex;
        gap: 0.5rem;
        align-items: baseline;
      }
    }
  }

  .signers_massive {
    grid-area: signers_massive;
    .container_inputs_signers_massive {
      display: grid;
      .flex_column {
        display: block;
      }

      .input_signers_massive {
        display: grid;
        grid-area: input_signers_massive;

        .input_legalRfc_massive {
          grid-area: input_legalRfc_massive;
          small {
            display: none;
          }
        }

        .legalName_massive {
          grid-area: legalName_massive;
        }

        .email_massive {
          grid-area: email_massive;
        }
      }

      .actions_massive {
        grid-area: actions_massive;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }

    &.padded {
      padding: 20px;
    }
  }

  .errors_massive {
    grid-area: errors_massive;
    min-width: 100px;
    &.padded {
      padding: 20px;
    }

    .error {
      padding-bottom: 20px;
      color: rgb(249, 92, 94);
    }

    .container_errors {
      .doc_error {
        margin: 0;
        font-weight: 500;
      }

      .message_error {
        font-size: 12px;
      }
    }
  }

  .info_to_upload_massive {
    grid-area: info_to_upload_massive;
    text-align: right;

    .time_to_upload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      p {
        margin: 0;
      }
    }
  }
}

.header_select_user_massive,
.body_select_user_massive {
  display: grid;
  grid-template-columns: 40% 60%;
  // gap: 5px;
  align-items: center;
  text-align: left;
  margin-top: 20px;
  max-height: 500px;
  overflow: auto;
}

.loading-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // transparencia del fondo
  z-index: 9999; // asegúrate de que la capa esté por encima de todo
}

/* MEDIA QUERIES */

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .contract_info_massive {
    grid-template-columns: 1fr;
    grid-template-areas:
      'info_files'
      'dropzone_massive'
      'title_contract_massive'
      'labels_massive'
      'list_labels_massive'
      'expires_massive';
    gap: 10px 0;

    .info_files {
      display: none;
    }
  }

  .labels_flex_massive {
    span {
      display: none;
    }
  }

  .container_inputs_signers_massive {
    grid-template-columns: 1fr;
    grid-template-areas:
      'actions_massive'
      'input_signers_massive';

    .input_signers_massive {
      grid-template-columns: 1fr;
      grid-template-areas:
        'input_legalRfc_massive'
        'legalName_massive'
        'email_massive';
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
  .contract_info_massive {
    grid-template-columns: 1fr;
    grid-template-areas:
      'info_files'
      'dropzone_massive'
      'title_contract_massive'
      'labels_massive'
      'list_labels_massive'
      'expires_massive';
    gap: 10px 0;

    .info_files {
      display: none;
    }
  }

  .labels_flex_massive {
    span {
      display: none;
    }
  }

  .container_inputs_signers_massive {
    grid-template-columns: 1fr;
    grid-template-areas:
      'actions_massive'
      'input_signers_massive';

    .input_signers_massive {
      grid-template-columns: 1fr;
      grid-template-areas:
        'input_legalRfc_massive'
        'legalName_massive'
        'email_massive';
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .contract_info_massive {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'dropzone_massive info_files'
      'title_contract_massive labels_massive'
      '. list_labels_massive'
      'expires_massive .';
    gap: 0 20px;

    .info_files {
      display: block;
    }
  }

  .labels_flex_massive {
    flex-wrap: nowrap !important;
    flex-direction: row !important;
    span {
      display: block;
    }
  }

  .container_inputs_signers_massive {
    grid-template-columns: 1fr;
    grid-template-areas: 'input_signers_massive actions_massive';
    gap: 0 10px;

    .input_signers_massive {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'input_legalRfc_massive legalName_massive legalName_massive';
      gap: 0 20px;

      .input_legalRfc_massive {
        display: flex;
        align-items: center;
        small {
          display: block !important;
          font-size: 16px;
          color: #6c6c6c;
          padding-right: 5px;
        }
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
