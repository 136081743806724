.contract_detail {
  padding: 15px;
  width: 90%;
  margin: 0 auto;

  /* Boton to return to my documents */
  .back {
    font-weight: 300;
    color: @color-gray;
    margin-top: 20px;
    display: block;
  }

  /* Title and image contract */
  .titlebar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    img {
      box-sizing: border-box;
      height: 50px;
    }
  }

  /* Grid with contract details */
  .grid_contract_details {
    padding: 30px;
    display: grid;

    .status_cd {
      grid-area: status_cd;
    }

    .description_cd {
      grid-area: description_cd;
    }

    .expires_cd {
      grid-area: expires_cd;
    }

    .labels_cd {
      grid-area: labels_cd;
    }

    .list_labels {
      grid-area: list_labels;
    }

    .authorizers_cd {
      grid-area: authorizers_cd;
    }

    .signers_list_cd {
      grid-area: signers_cd;
    }

    .recipients_cd {
      grid-area: recipients_cd;
    }

    .sign_contracts_cd {
      grid-area: sign_contract_cd;
      text-align: center;
    }

    .sign_addendum_cd {
      grid-area: sign_addendum_cd;
      .center_addendum_sign {
        text-align: center;
      }
    }

    /* STATUS CONTRACT */
    .status_cd {
      display: block;
      display: flex;
      flex-direction: column;
    }

    .status {
      padding: 10px 0;

      font-weight: 500;

      &.ready {
        color: @color-success;
      }

      &.pending {
        color: @color-warning;
      }
    }
    /* DESCRIPTION CONTRACT */
    .description_cd {
      p {
        font-weight: normal;
        margin: 0;
        padding: 10px 0;
      }
    }

    /* EXPIRES DATE CONTRACT */
    .expires_cd {
      p {
        font-weight: normal;
        margin: 0;
        padding: 10px 0;
      }
    }
    /* CONTRACT LABELS */
    .labels_cd {
      .title_labels {
        i {
          color: @color-primary;
          padding-left: 10px;
        }
      }

      .input_labels_cd {
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
          width: 100%;
        }
      }
    }

    .list_labels {
      overflow-x: auto;
      white-space: nowrap;
    }

    /* CONTRACT AUTHORIZERS */
    .authorizers_cd {
      margin-top: 20px;
      overflow-x: auto;
      .no_results {
        font-weight: normal;
      }

      .table_list_authorizers_cd {
        color: #555555;
        font-weight: normal;
        display: grid;
        border-collapse: collapse;
        align-items: center;
        grid-template-columns:
          40px
          260px
          minmax(260px, auto)
          minmax(260px, auto);
        grid-template-areas: 'status_turn_auth secondaryEmail_auth legalName_auth comments';
        gap: 0 20px;
        .status_turn_auth {
          // grid-area: status_turn_auth;
          display: flex;
          justify-content: left;
          i {
            padding-left: 10px;
            font-size: 18px;
          }
        }

        /* .legalRfc_auth {
          grid-area: legalRfc_auth;
        }

        .legalName_auth {
          grid-area: legalName_auth;
        }

        .comments {
          grid-area: comments;
        } */
      }
    }

    /* SIGNERS CONTRACT */
    .signers_list_cd {
      margin-top: 20px;
      overflow-x: auto;
      .no_results {
        font-weight: normal;
      }

      .table_list_signers_cd {
        .sign_document_data {
          color: #555555;
          font-weight: normal;
          display: grid;
          border-collapse: collapse;
          align-items: center;
          grid-template-columns:
            40px
            minmax(260px, auto)
            minmax(260px, auto)
            minmax(240px, auto)
            minmax(200px, auto)
            minmax(180px, auto);
          grid-template-areas: 'status_turn_signer secondaryEmail_signer legalName_signer change_signature_type_signer signature_date_signer';

          /* .column {
            max-width: 230px;
          } */

          .status_turn_signer {
            grid-area: status_turn_signer;
            display: flex;
            justify-content: left;
            i {
              padding-left: 10px;
              font-size: 18px;
            }
          }

          .legalRfc_signer {
            grid-area: legalRfc_signer;
          }

          .secondaryEmail_signer {
            grid-area: secondaryEmail_signer;
          }

          .legalName_signer {
            grid-area: legalName_signer;
          }

          .change_signature_type_signer {
            grid-area: change_signature_type_signer;
            position: relative;

            select {
              border: 1px solid #afaeae;
            }

            i {
              position: absolute;
              min-width: 20px;
              padding: 18px 0;
              right: 0;
              top: 0;
            }
          }

          .signature_date_signer {
            grid-area: signature_date_signer;
          }
        }

        /* VERIFICATION DOCS */
        .verifications_user_docs {
          display: flex;
          align-items: center;
          &:last-child {
            margin-bottom: 20px;
          }
          .title_verification {
            padding-right: 10px;
            font-size: 13px;
            span {
              color: #666a75;
            }
          }

          .user_documents {
            font-size: 12px;
            min-width: 500px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            span {
              color: #666a75;
              background: @color-white;
              padding: 5px;
              border-radius: 13px;
              border: 1px solid #666a75;
              margin-right: 10px;
              margin-top: 2px;
              cursor: pointer;
            }
          }
        }
      }
    }

    /* RECIPIENTS CONTRACT */
    .recipients_cd {
      overflow-x: auto;
      margin-top: 20px;
      .no_results {
        font-weight: normal;
      }
      // width: 80%;
      .table_list_recipient_cd {
        .recipient_show_cd {
          color: #555555;
          font-weight: normal;
          display: grid;
          align-items: center;
          border-collapse: collapse;
          grid-template-columns:
            40px
            260px
            350px;
          grid-template-areas: 'status_turn_copied secondaryEmail_copied legalName_copied';
          // grid-template-rows: 40px;
          gap: 0 20px;

          .status_turn_copied {
            grid-area: status_turn_copied;
            display: flex;
            justify-content: left;
            /* i {
              padding-left: 10px;
              font-size: 18px;
            } */
          }

          .legalRfc_copied {
            grid-area: legalRfc_copied;
          }

          .secondaryEmail_copied {
            grid-area: secondaryEmail_copied;
          }

          .legalName_copied {
            grid-area: legalName_copied;
          }
        }
      }
    }

    .sign_contracts_cd {
      .verification_warning {
        text-align: left;
        padding-left: 10px;

        p:first-child {
          margin: 0;
          margin-bottom: 10px;
        }

        .steps_to_verify {
          font-size: 14px;
          color: #6c6c6c;

          p:last-child {
            color: @color-primary;
          }
        }
      }
    }

    /* SELECT ALL ID TYPES IN CAMERA MODAL */
    .all_verification_types {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5px;
    }

    /* STEPPER TO VERIFY USER */
    .steps_to_verify_camera {
      display: flex;
      align-items: center;
      .div_step_take_picture {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-right: 10px;
        text-align: center;

        small {
          height: 40px;
          text-align: centers;
          margin-left: 10px;
          margin-top: 10px;
        }
      }
    }

    /* SHOW ADDENDUM FORM TO CREATE ADDENDUM */
    .create_addendum_form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 10px;
      label {
        width: 100%;
        padding: 10px;
      }
    }

    .add_addendum_cd {
      color: @color-primary;
      font-weight: 400;
      cursor: pointer;
    }
  }
}

.wrap_text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.select_display {
  position: relative;

  select {
    border: 1px solid #afaeae;
  }

  i {
    position: absolute;
    min-width: 20px;
    padding: 18px 0;
    right: 0;
    top: 0;
  }
}

.modify_data {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.update_signer_request_verification {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  color: #6c6c6c;
  margin-top: 5px;
  .input_checkbox {
    padding: 1px 0;
    padding-right: 5px;
    margin-bottom: 5px;
  }
  .tooltip_checkbox {
    padding: 1px 0;

    span {
      text-overflow: ellipsis;
    }
  }
}

.flex_edit {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;

  i {
    cursor: pointer;
    font-size: 14px;

    &:hover {
      color: @color-primary;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .grid_contract_details {
    grid-template-columns: 1fr;
    grid-template-areas:
      'status_cd'
      'expires_cd'
      'description_cd'
      'labels_cd'
      'list_labels'
      'authorizers_cd'
      'signers_cd'
      'recipients_cd'
      'sign_contract_cd'
      'sign_addendum_cd';

    .status_cd,
    .expires_cd {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row !important;
      align-items: center;
      span:first-child {
        padding-right: 10px;
      }

      p:last-child {
        font-weight: 500;
      }
    }

    .main_documents {
      border: none;
      padding-bottom: 0;
      margin-bottom: -15px;
      &.identity_docs {
        border: 1px solid @color-primary;
      }
    }
    .verification_warning {
      display: none;
    }

    /* SHOW STEPPER IN CAMERA MODAL */
    .steps_to_verify_camera {
      justify-content: center;
    }
  }

  .information {
    text-align: center;
  }

  .inputs_to_sign {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'cer'
      'key'
      'password';
    justify-items: center;

    div {
      small {
        display: none !important;
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
  .grid_contract_details {
    grid-template-columns: 1fr;
    grid-template-areas:
      'status_cd'
      'expires_cd'
      'description_cd'
      'labels_cd'
      'list_labels'
      'authorizers_cd'
      'signers_cd'
      'recipients_cd'
      'sign_contract_cd'
      'sign_addendum_cd';

    .status_cd,
    .expires_cd {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row !important;
      align-items: center;
      span:first-child {
        padding-right: 10px;
      }

      p:last-child {
        font-weight: 500;
      }
    }

    /* ALIGN STEPPER IN CAMERA MODAL */
    .steps_to_verify_camera {
      justify-content: space-around;
    }
  }

  .information {
    text-align: center;
  }

  .inputs_to_sign {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'cer'
      'key'
      'password';
    justify-items: center;

    div {
      small {
        display: none !important;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  .grid_contract_details {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'status_cd description_cd'
      'expires_cd labels_cd'
      '. list_labels'
      'authorizers_cd authorizers_cd'
      'signers_cd signers_cd'
      'recipients_cd recipients_cd'
      'sign_contract_cd sign_contract_cd'
      'sign_addendum_cd sign_addendum_cd';

    .status_cd,
    .expires_cd {
      flex-direction: column !important;
      align-items: flex-start;
    }
    /* ALIGN STEPPER IN CAMERA MODAL */
    .steps_to_verify_camera {
      justify-content: space-around;
    }

    .list_labels {
      &.active_labels {
        margin-top: -40px;
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .modal {
    &.contract_preview {
      width: 85%;
    }
  }

  .inputs_to_sign {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'cer key password';
    justify-items: center;
    div {
      small {
        display: block !important;
      }
    }
  }

  .grid_contract_details {
    .create_addendum_form {
      flex-wrap: nowrap !important;
    }

    .labels_cd {
      .input_labels_cd {
        display: flex;
        flex-direction: row !important;
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* Modals in view 'Mis documentos' */
}
