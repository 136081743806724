@charset "UTF-8";
.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: break-word;
}
.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}
.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: 700;
  margin: 0 0 5px 0;
}
.notification:focus,
.notification:hover {
  opacity: 1;
}
.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}
.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}
.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}
.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}
.notification-info {
  background-color: #2f96b4;
}
.notification-info:before {
  content: '';
}
.notification-success {
  background-color: #51a351;
}
.notification-success:before {
  content: '';
}
.notification-warning {
  background-color: #f89406;
}
.notification-warning:before {
  content: '';
}
.notification-error {
  background-color: #bd362f;
}
.notification-error:before {
  content: '';
}
